<template>
  <div class="readme-article">
    <h1 id="其它">四、其它</h1>
    <h2 id="添加学生">1.添加学生</h2>
    <p>可手动添加学员，需完善学员信息添加。</p>
    <p>
      <img src="@/assets/img/finance/4-1.png" />
    </p>
    <h2 id="积分兑换">2.积分兑换</h2>
    <p>
      查看积分兑换列表，可付费兑换积分。
    </p>
    <p>
      <img src="@/assets/img/finance/4-2.png" />
    </p>
    <h2 id="导入财务数据">3.导入财务数据</h2>
    <p>通过表格文件导入财务数据，根据模板示例来填写表格信息。</p>
    <p>
      <img src="@/assets/img/finance/4-3.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "finance4-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>